import '@/sass/styles.scss';
import 'alpinejs';
import 'flickity'; // Initialize via markup @see https://flickity.metafizzy.co/

// import { VueRelay } from '@/scripts/VueRelay';

// const vueComponents = [
//   ['example-component', () => import('@/vue/ExampleComponent')],
// ];

const customComponents = [
  ['body', () => import(/* webpackChunkName: "inviewport" */ '@/scripts/InViewport')],
  // ['[data-slider]', () => import(/* webpackChunkName: "inviewport" */ '@/scripts/Slider')],
];

window.App = new (class App {
  constructor() {
    window.addEventListener('DOMContentLoaded', () => this.onReady());
  }

  onReady() {
    // Initialize all custom components…
    customComponents.forEach(([tag, fn]) => {
      const $els = [...document.querySelectorAll(tag)];

      if ($els.length < 1) {
        return;
      }

      (async () => {
        const Constructor = (await fn()).default;

        $els.map(($el) => (new Constructor($el)).init());
      })();
    });

    // Initialize all single Vue components…
    // vueComponents.forEach(([tag, fn]) => VueRelay.parse(tag, fn));

    return this;
  }
})();
